.App {
  text-align: center;
}

.searchBar-container {
  margin-top: 0.5em;
}
/* .MuiPaper-elevation {
  background-color: #f9f9f9 !important;
} */
.unauthorizedmessage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.errorimg {
  width: 37%;
  height: auto;
}
.searchBoxContainer {
  height: 84vh;
  overflow-y: auto;
}
.mainContainer {
  height: 84vh;
  overflow-y: auto;
  position: relative;
  right: 8%;
}
.selectAllCheckboxGrid {
  display: flex;
  align-items: center;
  padding-left: 18px;
}
@media (min-width: 1300px) {
  .selectAllCheckboxGrid {
    max-width: 6.33% !important;
  }
}
@media (min-width: 1500px) {
  .selectAllCheckboxGrid {
    max-width: 5.33% !important;
  }
}
@media (min-width: 1800px) {
  .selectAllCheckboxGrid {
    max-width: 4.33% !important;
  }
}
/* @media (min-width: 1200px) {
  .selectAllCheckboxGrid {
    max-width: 4.33%;
  }
} */
.clearsearchgrid {
  display: flex;
  justify-content: space-around;
  align-self: flex-start;
}
.accordionheader {
  background-color: #fff !important;
}
.headergridtop {
  margin-top: 0px !important;
}
body {
  font-size: 14px !important;
}
.AccordionDetails1 {
  padding: 8px 12px 8px !important;
  /* width: 83% !important; */
}
/* .MuiInputBase-input {
  padding: 7px 0px !important;
  font-size: 12px !important;
}
.custom-input-label {
  font-size: 12px !important;
}
.custom-input-label:not(.MuiInputLabel-shrink) {
  font-size: 12px !important;
  top: -7px !important;
}
.stringvalueOutlinedInput {
  padding-left: 10px;
} */
.SearchResultList {
  padding: 0;
}
.SearchResultGrid {
  padding: 5px;
}
.SearchResultListItem {
  background-color: white;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
