// Here you can add other styles

.background {
  background-image: url('../images/background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.wrapper {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  // background: radial-gradient(
  //     70.83% 85.87% at 34.46% 29.17%,
  //     #435268 0%,
  //     #1d3551 36.98%,
  //     #0d2337 66.84%,
  //     #1d3551 100%
  //   ),
  //   #ffffff;
  background: #e4e4e4;
}
.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}
.btn-login-button {
  background-color: #000000;
  color: #ffffff;
}
.bg-login {
  background-color: white !important;
  color: #000000;
  opacity: 0.8;
}
.header {
  background-color: #60B796 !important;
  border: none !important;
}
.la
.sidebar {
  background-color: #0d1927 !important;
  border: none !important;
}
.breadcrumb-item::before {
  content: '' !important;
}
.footer {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  height: 3.01rem !important;
}
.app-logo {
  width: 200px;
  padding: 20px;
}

.breadcrumb-item.active {
  font-size: 24px;
  color: #ffffff;
}
.logout-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.colvaluewidth {
  width: 26.2%;
}
.bg-row-transparent {
  background-color: #ffffff;
  color: #2c2c2c;
  border: 1px solid #cccaca;
  text-align: center;
}

.round-circle {
  display: inline-block;
  margin-bottom: -12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  margin-left: 10px;
}

.tile-element {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #fb7358;
  border-radius: 6px;
  border-left: 15px solid #fb7358;
  &.yellow {
    border: 2px solid #febe00;
    border-left: 15px solid #febe00;
  }
  &.green {
    border: 2px solid #1eb488;
    border-left: 15px solid #1eb488;
  }
}
.guage-alignment {
  padding-inline-start: 5px;
}
.customCard {
  .cardCount {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #555555;
  }
  .cardTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #929292;
  }
  .cardPercentage {
    font-size: 13px;
    .percentageIcon {
      width: 24px;
      height: 18.17px;
      display: inline-block;
      vertical-align: sub;
      &.upIcon {
        background: url(../images/up-icon.png);
      }
      &.downIcon {
        background: url(../images/down-icon.png);
      }
    }
    .percentageText {
      color: #555555;
      &.upIconText {
        color: #579c21;
      }
      &.downIconText {
        color: #e44659;
      }
    }
  }
  .cardIcon {
    width: 60px;
    height: 70.02px;
    float: right;
    img {
      width: 50px;
    }
  }
}
.leaflet-container {
  width: 100% !important;
}

.leaflet-bottom {
  display: none !important;
}
